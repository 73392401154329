import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import YouMayLike from "../components/you-may-like"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/line-item"
import { formatPrice } from "../utils/format-price"
import {
  table,
  wrap,
  totals,
  grandTotal,
  summary,
  checkoutButton,
  collapseColumn,
  labelColumn,
  imageHeader,
  productHeader,
  emptyStateContainer,
  emptyStateHeading,
  emptyStateLink
} from "./cart.module.css"

export default function CartPage(props) {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl, "_self")
  }

  const cartData = props.data.allContentfulCheckoutPage.edges[0].node
  const singleStageData = props.data.allContentfulSingleStageSeatBeltRepairService.edges
  const dualStageData = props.data.allContentfulDualStageSeatBeltRepairService.edges
  const tripleStageData = props.data.allContentfulTripleStageSeatBeltRepairService.edges
  const pretensionerData = props.data.allContentfulPretensionerService.edges
  const seatBeltColorData = props.data.allContentfulSeatBeltColoringService.edges
  const seatBeltWebbingData = props.data.allContentfulSeatBeltWebbingReplacement.edges
  const seatCoverStitchingData = props.data.allContentfulSeatCoverStitching.edges
  const fullSeatReupholsteringData = props.data.allContentfulFullSeatReupholstering.edges
  const batteryCableData = props.data.allContentfulBatteryCableRepairService.edges
  const airbagPlugsData = props.data.allContentfulAirbagPlugsProduct.edges
  const steeringColumnData = props.data.allContentfulSteeringColumnSensorRepairService.edges
  const srsAirBagData = props.data.allContentfulSrsAirbagModuleRepairService.edges
  const convertibleRollBarData = props.data.allContentfulConvertibleRollBarService.edges


  return (
    <Layout>
      <Seo
        title="Cart - L&D Solutions Inc."
        location={props.location.pathname}
      />
      <div>
        <div className={wrap}>
          {emptyCart ? (
            <div className={emptyStateContainer}>
              <h1 className={emptyStateHeading}>Your cart is empty</h1>
              <p>
                Looks like you haven’t selected anything yet. Take a minute to browse through some of our services:
              </p>
              <Link to="/services" title="View Services" alt="View Services - L&D Solutions" className={emptyStateLink}>
                View Our Services
              </Link>
            </div>
          ) : (
            <>
              <h1 className="text-2xl lg:text-3xl font-bold pt-10 pb-10 mx-auto">Your Cart</h1>
              <table className={table}>
                <thead>
                  <tr>
                    <th className={imageHeader}>Image</th>
                    <th className={productHeader}>Product</th>
                    <th className={collapseColumn}>Price</th>
                    <th>Qty.</th>
                    <th className={[totals, collapseColumn].join(" ")}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}
                  <tr className={summary}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Taxes:</td>
                    <td className={totals}>
                      Calculated at checkout
                    </td>
                  </tr>
                  <tr className={summary}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Shipping:</td>
                    <td className={totals}>Calculated at checkout</td>
                  </tr>
                  <tr className={grandTotal}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Subtotal:</td>
                    <td className={totals}>
                      {formatPrice(
                        checkout.totalPriceV2.currencyCode,
                        checkout.totalPriceV2.amount
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="container mx-auto">
                <div className="flex justify-center">
                  <div className="text-center pt-10" dangerouslySetInnerHTML={{
                    __html: cartData.text.childMarkdownRemark.html,
                  }} />
                </div>
              </div>
              <div className="pb-10">
                <button
                  onClick={handleCheckout}
                  disabled={loading}
                  className={checkoutButton}
                  title="Continue To Checkout"
                  alt="Continue To Checkout"
                >
                  Proceed To Checkout
                </button>
              </div>
            </>
          )}
        </div>
        <YouMayLike
          singleStageData={singleStageData}
          dualStageData={dualStageData}
          tripleStageData={tripleStageData}
          pretensionerData={pretensionerData}
          seatBeltColorData={seatBeltColorData}
          seatBeltWebbingData={seatBeltWebbingData}
          seatCoverStitchingData={seatCoverStitchingData}
          fullSeatReupholsteringData={fullSeatReupholsteringData}
          batteryCableData={batteryCableData}
          steeringColumnData={steeringColumnData}
          srsAirBagData={srsAirBagData}
          convertibleRollBarData={convertibleRollBarData}
          airbagPlugsData={airbagPlugsData}
        />
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
query {
    allContentfulCheckoutPage {
    edges {
      node {
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
    allContentfulSingleStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }  
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulDualStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulTripleStageSeatBeltRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPretensionerService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSeatBeltColoringService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSeatBeltWebbingReplacement {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }  
    allContentfulSeatCoverStitching {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }  
    allContentfulFullSeatReupholstering {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }    
    allContentfulBatteryCableRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSteeringColumnSensorRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSrsAirbagModuleRepairService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
      allContentfulAirbagPlugsProduct {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          }
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulConvertibleRollBarService {
      edges {
        node {
          servicePageBodyText {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: NONE)
            description
            title
          }    
          specificationsTitle1
          specificationsTitle2
          specificationsTitle3
          specificationsTitle4
          specificationsTitle5
          specificationsBody1 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody2 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody3 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody4 {
            childMarkdownRemark {
              html
            }
          }
          specificationsBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksTitle6 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody1 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody2 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody3 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody4 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody5 {
            childMarkdownRemark {
              html
            }
          }
          howItWorksBody6 {
            childMarkdownRemark {
              html
            }
          }
          faq {
            question
            answer
          } 
          textColumns {
            title
            body
          }
          footerText
          optionalAdditionalText {
            optionalAdditionalText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
}
`